<template>
  <div>
    <Heard />
    <!-- <Tip /> -->
    <div class="container person-box">
      <Aside />
      <div class="right-box">
        <div class="top">
          <p>我的简历</p>
          <p class="btn" @click="mantle = true">上传简历</p>
        </div>
        <div class="cont">
          <div class="time123" v-if="filePath && filePath != 'null'">
            <el-image
              v-if="isimgs == 1"
              :src="filePath"
              :preview-src-list="[filePath]"
              alt=""
            />
            <a :href="filePath" target="_blank" rel="noopener noreferrer"
              ><img v-if="isimgs == 2" src="@/assets/image/p.png" alt=""
            /></a>
            <a :href="filePath" target="_blank"
              ><img v-if="isimgs == 3" src="@/assets/image/w.png" alt=""
            /></a>
            <div class="time">
              <p>我的简历</p>
              <p class="t1">更新时间:{{ filePathPcTime }}</p>
            </div>
          </div>
          <el-empty
            v-if="!filePath || filePath == 'null'"
            description="请上传简历"
          ></el-empty>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="mantle" width="400px">
      <el-upload
        class="upload-demo"
        ref="upload"
        drag
        :limit="1"
        accept="image/jpeg,image,image/png,image/bmp,.doc,.docx,.pdf,.jpg,.jpeg"
        :before-upload="beforeUpload"
        :on-success="fileupload"
        :on-remove="handleRemove"
        :headers="header"
        action="http://ssck.hrclass.com.cn:8083/file/upLoadAptResume"
        show-file-list
        :file-list="fileList"
        :auto-upload="false"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">
          上传文件只能是pdf、doc、docx、png、jpg、jpeg格式
        </div>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="diagnow()"
          >完成</el-button
        >
      </span>
    </el-dialog>
    <Footer />
  </div>
</template>

<script>
import { setfile, getUser } from "@/plugins/auth.js";
import { api_webqueryUser } from "@/apis/apis";

import Heard from "@/components/Heard.vue";
// import Tip from "@/components/Tip.vue";
import Aside from "@/components/Aside.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Heard,
    // Tip,
    Aside,
    Footer,
  },
  data() {
    return {
      isimgs: 1,
      filePath: "",
      filePathPcTime: "",
      mantle: false,
      fileList: [],
      file: "",
      userId: getUser().id,
      header: {
        userId: getUser().id,
      },
    };
  },
  created() {
    // this.filePath = getfile().filePath;
    // if (this.filePath) {
    //   console.log(typeof this.filePath, "this.filePath12234 ");
    // }
    this.getfilepath();
  },
  methods: {
    getfilepath() {
      api_webqueryUser({
        id: getUser().id,
      }).then((res) => {
        this.filePath = res.data.data.filePathPc;
        console.log(
          this.filePath.substring(this.filePath.lastIndexOf(".") + 1),
          "111"
        );
        let last = this.filePath.substring(this.filePath.lastIndexOf(".") + 1);
        if (last == "png" || last == "jpg" || last == "jpeg") {
          this.isimgs = 1;
        } else if (last == "pdf") {
          this.isimgs = 2;
        } else {
          this.isimgs = 3;
        }
        this.filePathPcTime = res.data.data.filePathPcTime;
      });
    },

    // 上传文件之前
    beforeUpload(file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      const whiteList = ["pdf", "doc", "docx", "png", "jpg", "jpeg"];
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message.error(
          "上传文件只能是 pdf、doc、docx、png、jpg、jpeg格式"
        );
        return false;
      }
    },

    //文件上传成功
    fileupload(response, file, fileList) {
      // console.log(response, "response");
      // console.log(file, "file");
      // console.log(fileList, "fileList");
      let oldarr = [];
      fileList.forEach((item) => {
        if (!oldarr.includes(item.response.data.url)) {
          oldarr.push(item.response.data.url);
        }
      });
      this.file = oldarr;
      setfile(this.file);
      this.getfilepath();
      this.fileList = [];
      console.log(this.file, "赋值后");
    },

    handleRemove(file, fileList) {
      let oldarrremove = [];
      fileList.forEach((item) => {
        if (!oldarrremove.includes(item.response.data.url)) {
          oldarrremove.push(item.response.data.url);
        }
      });
      this.file = oldarrremove;
      this.fileList = [];

      console.log(this.file, "赋值后111");
    },
    diagnow() {
      // if (this.file.length == 0) {
      //   this.$message({
      //     message: "您还未上传简历,请先上传",
      //     type: "warning",
      //   });
      //   return;
      // } else {

      this.$refs.upload.submit();

      this.mantle = false;
      // this.$router.go(0);
      // }
    },
  },
};
</script>

<style lang="less" scoped>
.person-box {
  display: flex;
  .right-box {
    min-width: 960px;
    border: 1px solid #f2f2f2;
    padding: 20px;

    .top {
      display: flex;
      justify-content: space-between;
      font-size: 22px;
      .btn {
        width: 120px;
        height: 40px;
        background-color: #2d69f9;
        line-height: 40px;
        text-align: center;
        font-size: 16px;
        color: #fff;
        border-radius: 4px;
        cursor: pointer;
      }
    }
    .cont {
      border: 1px solid #ccc;
      width: 100%;
      height: 500px;
      .time123 {
        display: flex;
        img {
          width: 200px;
          height: 280px;
          margin-top: 40px;
          margin-left: 40px;
          border: 1px dashed #ccc;
        }
        .time {
          margin-top: 80px;
          margin-left: 40px;
          font-weight: bold;
          .t1 {
            font-weight: 400;
            margin-top: 20px;
          }
        }
      }

      .el-image {
        width: 200px;
        height: 280px;
        margin-top: 40px;
        margin-left: 40px;
        border: 1px dashed #ccc;
      }
    }
  }
}
</style>