<template>
  <div class="aside-box">
    <h2>个人中心</h2>

    <router-link
      :to="{ path: '/personal' }"
      :class="{ item: true, isbgc: $route.path == '/personal' }"
    >
      <img
        v-show="$route.path == '/personal'"
        src="@/assets/image/aside-icon.png"
        alt=""
      />
      <span>基本信息</span>
    </router-link>

    <router-link
      :to="{ path: '/headimg' }"
      :class="{ item: true, isbgc: $route.path == '/headimg' }"
    >
      <img
        v-show="$route.path == '/headimg'"
        src="@/assets/image/aside-icon.png"
        alt=""
      />
      <span>设置头像</span>
    </router-link>

    <router-link
      :to="{ path: '/phone' }"
      :class="{ item: true, isbgc: $route.path == '/phone' }"
    >
      <img
        v-show="$route.path == '/phone'"
        src="@/assets/image/aside-icon.png"
        alt=""
      />
      <span>手机绑定</span>
    </router-link>
    <router-link
      :to="{ path: '/pwd' }"
      :class="{ item: true, isbgc: $route.path == '/pwd' }"
    >
      <img
        v-show="$route.path == '/pwd'"
        src="@/assets/image/aside-icon.png"
        alt=""
      />
      <span>修改密码</span>
    </router-link>
    <!-- <router-link
      :to="{ path: '/order' }"
      :class="{ item: true, isbgc: $route.path == '/order' }"
    >
      <img
        v-show="$route.path == '/order'"
        src="@/assets/image/aside-icon.png"
        alt=""
      />
      <span>我的订单</span>
    </router-link> -->

    <h2 class="sech2">简历中心</h2>

    <router-link
      :to="{ path: '/resume' }"
      :class="{ item: true, isbgc: $route.path == '/resume' }"
    >
      <img
        v-show="$route.path == '/resume'"
        src="@/assets/image/aside-icon.png"
        alt=""
      />
      <span>投递记录</span>
    </router-link>
    <router-link
      :to="{ path: '/myresume' }"
      :class="{ item: true, isbgc: $route.path == '/myresume' }"
    >
      <img
        v-show="$route.path == '/myresume'"
        src="@/assets/image/aside-icon.png"
        alt=""
      />
      <span>我的简历</span>
    </router-link>
  </div>
</template>

<script>
export default {
  created() {
    //  console.log(this.$route);
    // console.log(this.$store.state);
  },
};
</script>

<style lang="less" scoped>
.aside-box {
  width: 220px;
  height: 460px;
  margin-right: 20px;
  border: 1px solid #f2f2f2;
  padding: 20px 0;

  h2 {
    line-height: 50px;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: bold;
    color: #000;
    width: 90%;
    margin-left: 5%;
    border-bottom: 1px solid #f2f2f2;
  }
  .isbgc {
    background: linear-gradient(
      271deg,
      rgba(45, 105, 249, 0.3) 0%,
      rgba(45, 105, 249, 0.5) 100%
    );

    color: #2d69f9;
  }
  .item {
    display: block;
    width: 100%;
    padding: 0 20px;
    height: 40px;
    line-height: 40px;
    position: relative;
    color: #000;

    span {
      font-size: 14px;
    }
    img {
      position: absolute;
      left: 0px;
      top: 6px;
    }
  }
}
</style>